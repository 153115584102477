import React from 'react';
import Heading from '../../atoms/heading';
import CoverPage from '../../molecules/cover';
import SubscribeBox from '../../templates/subscribeBox';
import './upcoming.css';




const UpcomingPage = () => {
  // const [data, status] = useFetch('/upcoming')
  return (
    <div className="upcoming-page">
    {/* <h1>Upcoming events</h1> */}
      <div className="header">
        <Heading 
          text = "Upcoming Events"
          underline = {true}
        />
      </div>
      {/* conditional render cover page or event previews */}
      <CoverPage 
        img = "assets/ball.png"
        text= "New Events are coming very soon, subscribe below to get the latest updates!"
      />
      
    {/* mail service opt. */}
    <SubscribeBox />
    </div>
  );
}
 
export default UpcomingPage;