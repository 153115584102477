import React from 'react';
import './footerbox.css';


const FooterBox = ({title,text,button,onClick}) => { 
  return <div className="donation-box">
            <h1>{title}</h1>
            <p>{text}</p>
            <button onClick={onClick}>{button}</button>
         </div>
}


export default FooterBox;