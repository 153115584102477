import React from 'react';
import {Link} from 'react-router-dom';
import './navbar.css';


const burgerClickHandler= ()=>{
      const burgerLinks = document.querySelector('.navbar-burger-links')
      if(burgerLinks.classList.contains('hidden')){
        burgerLinks.classList.remove('hidden')
      }
      else burgerLinks.classList.add('hidden')
}

const linkClickHandler = (e) =>{
  const allLinks = document.querySelectorAll('.navbar-links ul li')
  allLinks.forEach(l => l.classList.remove('active-link'))
  e.target.classList.add('active-link')
}
const closeBurger = () => {
  const menu  = document.querySelector('.navbar-burger-links')
  menu.classList.add('hidden');
  // console.log(menu)
}
const NavLink = ({title, onClick}) => {
  return <Link to={`/${title}`}><li onClick={onClick}>{title}</li></Link>
}

const Navlinks = ({onClick}) => {
  const routes = ['Home','About','Events','Upcoming']
 
  return  <>
            <ul>
                {routes.map((route, index) => <NavLink 
                  key = {index}
                  title={route}
                  onClick={onClick}
                 />)}
            </ul>
         </>
}

const Navbar = () => {
  return (
    <>
    <nav>
      <div className="navbar-title">
        <Link to="/"><p>Global Society</p></Link>
      </div>
      <div className="navbar-links">
        <Navlinks onClick={linkClickHandler} />
      </div>
      <div className="navbar-burger" onClick={burgerClickHandler}>
        <i className="fa fa-globe fa-1.5x"></i>        
      </div>
    </nav>
    <div className="navbar-burger-links hidden">
      <Navlinks onClick={closeBurger} />
    </div>
  </>
  );
}
 
export default Navbar;