import React from 'react';
import './formfield.css';



const FormTextFeild = ({type,placeholder,size,height, value, setValue}) => {
  const style = {
    width:size,
    height: height ? height : undefined
  }
  return (
    <input type = {type} placeholder={placeholder} style = {style} value={value ? value : ""} onChange = {(e) => setValue(e.target.value)}></input>
  );
}
 
export default FormTextFeild;