import React from 'react';
import './event.css';

const EventPreview = ({img, title, para}) => {
  return <div className="preview">
            <img 
              className="circular-img"
              alt="event-img"
              src={img}
            />
            <div>
              <h6>{title}</h6>
              <p>{para}</p>
            </div>
            
        </div>
}

export default EventPreview;