import React, { useEffect, useState } from 'react';
import useFetch from '../../../hooks/useFetch';
import Button from '../../atoms/button';
import EventPreview from '../../atoms/eventPreview';
import FormTextFeild from '../../atoms/formTextFeild';
import LoadingSpinner from '../../atoms/loadingSpinner';
import Pagination from '../../atoms/pagination';
import Fuse from 'fuse.js'
import './event.css'


const EventPage = () => {
  const [search, setSearch] = useState("");
  const [EventData, status] = useFetch('/tournament/preview');
  const [currentData,setCurrentData] = useState([]);
  // current page for pagination
  const [currentPage, setCurrentPage] = useState(1);
  const postPerPage = 4;

  // index for data of array
  const indexOfLastElement = currentPage*postPerPage;
  const indexOfFirstElement = indexOfLastElement - postPerPage;

  // data to show on current page
  useEffect(()=>{
    // cleaning the response array
    const dataToShow = EventData.map((e)=>e.data);
    setCurrentData(dataToShow.slice(indexOfFirstElement, indexOfLastElement));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[status, indexOfFirstElement, indexOfLastElement])
  

  // function for pagination getting pageNumber form pagination component.
  const paginate = (pageNumber) =>{
    setCurrentPage(pageNumber);
  }

  if(status!==200) return <LoadingSpinner loading={true} />

// initialising search area for fuse!
  const fuse = new Fuse(EventData, {
    keys: ['data.title', "data.para"]
  })

// search function powered bu fuse js
  const SearchFunction = (e) =>{
    e.preventDefault();
    if(search){
      let items = fuse.search(search);
      let dataToShow = items.map((e)=> e.item.data);
      if(dataToShow.length)
        setCurrentData(dataToShow)
      return;
    }
    else return;
  }
  
  const eventpreviews= (event, index) => {
    return <EventPreview 
      key={index} 
      img={event.img.fields.file.url}
      title={event.title}
      para={event.para}
  />
  }
  return (
    <div className="event-page">
    {/* search box */}
    <form className="search-event-form">
      <FormTextFeild 
        type="text"
        placeholder="Search"
        value={search}
        setValue={setSearch}
      />
      <Button onClick={SearchFunction} child = {<i className="fa fa-search fa-1x"></i>}/>
    </form>
    
    {/* filter */}
    <div className="events-previews-div">
      {currentData.map(eventpreviews)}
    </div>
    <Pagination 
      totalPosts= {EventData.length}
      postPerPage = {postPerPage}
      paginate = {paginate}
    />
    </div>
  );
}
 
export default EventPage;