import React from 'react';
import Divider from '../../atoms/divider';
import Introduction from '../../molecules/introduction';
import Jumbotron from '../../molecules/jumbotron';
import IntroData from './data/intro.json';
import TestimonyData from './data/testimonies.json'
import Testimony from '../../molecules/testimonies';
import ContactBox from '../../templates/contactBox';
import ServiceData from './data/services.json';

const HomePage = () => {
  
  return (
    <>
        <Jumbotron 
           {...IntroData}
        />
        <Introduction 
          heading="Our Work and Services"
          content = {ServiceData}
        />
        {/* use glide.js or owl.js to create a caraousel */}
        {/* <Testimony 
            testimonies = {TestimonyData}
        /> */}
        <ContactBox />
     </>
  );
}
 
export default HomePage;