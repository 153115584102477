import React, { useState } from 'react';
import Button from '../../atoms/button';
import FormTextFeild from '../../atoms/formTextFeild';
import postRequest from '../../../util/postRequest';
import './form.css';

const ContactForm = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [display, setDisplay]  = useState('');

  const submitForm = async (e) =>{
    e.preventDefault();
    setDisplay('*message is being sent....');
    if(!name || !email || !message){
      setDisplay("*please fill all the fields");
      return;
    }
    // eslint-disable-next-line
    const [status, response] = await postRequest('/feedback',{
      name, email, message
    },undefined); 
    if(status===404){
      setDisplay('*please provide a valid email id');
    }else{
      setDisplay('Successfully recieved your message!')
    }
    return;
  }

  return (
    <form className = "contact-form col-flex">
      
      <div className = "row-flex">
        <FormTextFeild 
          type="text"
          placeholder = "name"
          size="50%"
          value = {name}
          setValue = {setName}
        />
        <FormTextFeild 
          type="email"
          placeholder = "email"
          size="50%"
          value = {email}
          setValue = {setEmail}
        />
      </div>
        <textarea type="text" placeholder="Your message for us..." onChange={(e) => setMessage(e.target.value)} value={message} >
        </textarea>
        <Button 
          child = "Submit"
          onClick= {submitForm}
        />
        <p style={{
        color:"orange",
        fontSize:"18px"
      }}>{display}</p>
    </form>
  );
}

export default ContactForm;