import React from 'react';
import { css } from "@emotion/core";
import ClipLoader from "react-spinners/ClipLoader";

const override = css`
  display: block;
  margin: 10% auto;
`;

const LoadingSpinner = ({loading}) => {
  return (
    <div style= {{height:"100vh", justifyContent:"center"}}>
      <ClipLoader
        css= {override}
        size={150}
        color={"#5BD3DA"}
        loading={loading}
    />
  </div>
  );
}
 
export default LoadingSpinner;