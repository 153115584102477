import React from 'react';
import Heading from '../../atoms/heading';
import './jumbotron.css'

const Jumbotron = ({img,heading,text}) => {
  return (
    <div className="jumbotron">
      <img src={img} alt="logo" />
      <div className="jumbotron-content">
      <Heading text = {heading} />
        <p>{text}</p>
      </div>
    </div>
  );
}
 
export default Jumbotron;