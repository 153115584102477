// eslint-disable-next-line no-unused-vars
import {DEV_BASE_URL , PROD_BASE_URL} from '../../values';

const postRequest = async (url, body, authorization) => {
  try{
    const response = await fetch(PROD_BASE_URL + url,{
      method: 'POST',
      mode: 'cors',
      authorization:authorization,
      headers: {
        'Content-Type': 'application/json'
      },
      body:JSON.stringify(body)
    });
    const data = await response.json();
    return [response.status, data];
  }
  catch(e){
    console.log(e.message)
    return [404, e.message];
  }
    
}

export default postRequest;