import React from 'react';
import FooterBox from '../../atoms/footerBox';
import {useHistory} from 'react-router-dom';


const DonationBox = () => {
  const history = useHistory();
  return (
    <FooterBox 
      title="Make a Donation"
      text="Global Society is registered under a non-profit society act. Our aims are to provide sports education avialable to everyone and everywhere. Your small contribution can make a large difference."
      button="Donate"
      onClick = {()=>{history.push('/donate')}}
    />
  );
}
 
export default DonationBox;