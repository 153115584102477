import React from 'react';
import Heading from '../../atoms/heading';
import ContentCard from '../../atoms/contentCard';
import './introduction.css';




const Introduction = ({heading,content}) => {

  function contentCardMap(data, index){
    return <ContentCard 
      key = {index}
      img= {data.img}
      title={data.title}
      para = {data.para}
      reverse = {index%2}
    />
  }
  return (
    <div className="introduction">
        <Heading 
          text={heading}
          underline={true}
        />
        <div>
            {content.map(contentCardMap)}
        </div>
    </div>
  );
}
 
export default Introduction;