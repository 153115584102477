import React , {useState} from 'react';
import postRequest from '../../../util/postRequest';
import FormTextFeild from '../../atoms/formTextFeild';
import * as EmailValidator from 'email-validator';
import './subscribe.css';

const SubscribeBox = () => { 
  const [email, setEmail] = useState();
  const [message, setMessage] = useState('');

  async function submitHandler(e){
    e.preventDefault();
    const emailCheck = EmailValidator.validate(email);
    if(!emailCheck){
      setMessage('please provide a valid email');
      return;
    }
    const [status, data] = await postRequest('/subscribe',{email});
    console.log(data);
    if(status === 404){
      setMessage('you are already subscribed!');
      return;
    }else{
      setMessage('You have been added to our list!✅')
      return;
    }
  }
  
  return <div className="donation-box subscribe-box">
            <h1>Subscribe</h1>
            <p>Get all the updates of new events organised by global society directly into your mail.</p>
            <FormTextFeild 
              value = {email}
              setValue = {setEmail}
              type = "email"
              placeholder="email"
            />
            <button onClick={submitHandler}>subscribe</button>
            <p style = {{color:"orange"}}>{message}</p>
         </div>
}

export default SubscribeBox;