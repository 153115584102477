import React from 'react';
import './cover.css';

const CoverPage = ({img, text}) => {
  return (
   <div className="cover-page">
    <div>
     <img src={img} alt="pic"/>
    </div>
    <h6>{text}</h6>
  </div>
  );
}
 
export default CoverPage;