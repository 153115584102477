import React from 'react';
import './heading.css';


const Heading = ({text, underline}) => {
  let classes="heading"
  if(underline){
    classes= "heading underline"
  }
  return (
    <h1 className={classes}>{text}</h1>
  );
}
 
export default Heading;