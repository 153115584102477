import React from 'react';
import {useHistory} from 'react-router-dom';
import FooterBox from '../../atoms/footerBox';


const ContactBox = () => {
  const history = useHistory();
  return (
    <FooterBox 
      title="Contact Us"
      text="Have any queries, Want to organise a tournament with us?
      Get to know us better, get in touch to better understand our working and ethics. Just leave us a message and we'll get back to you!"
      button="Contact"
      onClick = {()=> history.push('/contact')}
    />
  );
}
 
export default ContactBox;