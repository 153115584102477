import './App.css';
import Navbar from './components/templates/navbar'
import HomePage from './components/organisms/home';
import {
  BrowserRouter as Router,
  Route,
  Switch
} from 'react-router-dom';
import AboutPage from './components/organisms/about';
import Footer from './components/templates/footer';
import EventPage from './components/organisms/event';
import ContactPage from './components/organisms/contact';
import DonationPage from './components/organisms/donate';
import UpcomingPage from './components/organisms/upcoming';

function App() {
  return (
    <Router>
      <div className="App">
        <Navbar />
        <div className="wrapper">
          <Switch>
            <Route exact path="/" component={HomePage} />
            <Route exact path="/Home" component={HomePage} />
            <Route path="/about" component={AboutPage} />
            <Route path="/events" component={EventPage} />
            <Route path="/contact" component={ContactPage} />
            <Route path="/donate" component={DonationPage} />
            <Route path="/upcoming" component={UpcomingPage} />
          </Switch>
        </div>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
