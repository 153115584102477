import React from 'react';
import './contactpage.css'
import ContactForm from '../../molecules/contactForm';
import ContactInfo from '../../molecules/contactInfo';
import PhotoHeading from '../../atoms/photoHeading';





const ContactPage = () => {
  return (
    <div className="contact-page">
      <PhotoHeading 
        title="Contact Us"
        photo="https://t3.ftcdn.net/jpg/03/09/32/74/240_F_309327405_nwDRBIlJHwAdGQM6E5aYhLjt0odVHshv.jpg"
      />
      <section className="content-section">
        <ContactInfo />
        <ContactForm />
      </section>
    </div>
  );
}
 
export default ContactPage;