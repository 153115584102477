import React from 'react';
import './info.css';
const ContactInfo = () =>{
  return <div className="contact-info">
              <h4>Find us!</h4>
              <p>Any query, any event you want to organise with us, you can directly write to us from the form given on this page and we will get back to us on your email.</p>
              <h4>Contact Information</h4>
              <p>+91 9829016664</p>
              <p>471, Carewell hospital near anandpuri park, bees dukan Adarsh Nagar, Jaipur Rajasthan 302004 </p>
              <p>globalsocietysports@gmail.com</p>
        </div>
}

export default ContactInfo