import React, { useState } from 'react';
import Button from '../../atoms/button';
import FormTextFeild from '../../atoms/formTextFeild';
import Heading from '../../atoms/heading';
import PhotoHeading from '../../atoms/photoHeading';
import postRequest from '../../../util/postRequest';
import { loadStripe } from '@stripe/stripe-js';
import './donate.css';
import LoadingSpinner from '../../atoms/loadingSpinner';

const stripePromise = loadStripe('pk_live_51HcG2eBBBiCxLKM5KNo1dPum0FLepVZUPuwom4BGv1DTwZJYehqvYHTvuyiC3pcbiuvQsGZD5VmCwNRAyWA9m5lp00Qr21IE2g');

const DonationPage = () => {
  const [amount , setAmount] = useState();
  const [loading, setLoading] = useState(false);
  const handleClick = async (event) => {
    event.preventDefault();
    setLoading(true);
    try{
      const stripe = await stripePromise;
      // eslint-disable-next-line
      const [status, session] = await postRequest('/donation/create-checkout-session', {
        amount:amount*100
      })
      // eslint-disable-next-line
      const result = await stripe.redirectToCheckout({
        sessionId: session.id,
      });
    }
    catch(e){
      console.log(e.message);
    }
  };
  if(loading){
    return <LoadingSpinner loading={loading} />
  }
  return (
    <div className="donation-page">
    <PhotoHeading 
      title="Donate"
      photo="https://images.unsplash.com/photo-1603722039047-bc9997bfa963?ixlib=rb-1.2.1&auto=format&fit=crop&w=1000&q=400"
    />
    <div className="donation-content">
      <div className="heading-div">
        <Heading
          text="Global Society is registered under a non-profit society act. "
        />
        <p>Global Society Ngo strives to provide opportunities for socio-economic and spiritual development of vulnerable group like street and game playing children, rural poor, girls in general and the aberrant youth towards attaining the envisioned society.
          Your small contribution can make a large difference!</p>
      </div>
      <form className="donation-form">
        <div>
          <FormTextFeild 
            type="text"
            placeholder="amount in (rupees)"
            value={amount}
            setValue= {setAmount}
          />
        <Button  
          child="checkout"
          onClick = {handleClick}
        />
        </div>
      </form>
      </div>
    </div>
  );
}
 
export default DonationPage;