import React from 'react';
import './button.css';

const Button = ({child, onClick}) => {
  return (
    <button className="custom-button" role="link" onClick={onClick}>
        {child}
    </button>
  );
}
 
export default Button;