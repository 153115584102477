import React from 'react';
import './section.css';

const PhotoHeading = ({title, photo}) => {
  return (
    <section className="top-section" style= {{background: `url(${photo})`,backgroundRepeat: "no-repeat", backgroundSize:"cover"}}>
        <h4>{title}</h4>
    </section>
  );
}
 
export default PhotoHeading;