import React, {useRef} from 'react';
import './pagination.css';

const Pagination = ({postPerPage, totalPosts, paginate}) => {
  const list  = useRef(null);
  const pages = [];
  for(let i=1;i<=Math.ceil(totalPosts/postPerPage);i++){
    pages.push(i);
  }
  function onClickHandler(pageNumber){
    paginate(pageNumber);
    const listItems = list.current.childNodes;
    listItems.forEach((li)=>{
      // console.log(typeof(li.innerText), typeof(pageNumber));
      if(li.innerText === pageNumber.toString()) li.classList.add('active-page');
      else li.classList.remove('active-page');
    });
    
  }
  return (
    <ul className="pagination-list" ref = {list}>
      {pages.map((pageNumber)=>{
       return <li className="pagination-list-item"  key={pageNumber} onClick={()=> onClickHandler(pageNumber)}>{pageNumber}</li>
      })}
    </ul>
  );
}
 
export default Pagination;