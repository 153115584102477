import React from 'react';
import Introduction from '../../molecules/introduction';
import Jumbotron from '../../molecules/jumbotron';
import DonationBox from '../../templates/donationBox';
import IntroData from './data/intro.json';
import ServiceData from './data/services.json';
const AboutPage = () => {
  return (
    <>
      <Jumbotron
        {...IntroData}
       />
      
       <Introduction 
         heading="Working for sports"
         content={ServiceData}
       />
       <DonationBox />
        {/* <DonationBox /> */}
        {/* <ContactBox /> */}
    </>
  );
}


 
export default AboutPage;