import React from 'react';
import './footer.css'

const Footer = () => {
  return (
    <>
    <footer>
      <div className="contact">
        <h4>Contact Us</h4>
        <p>+91 9829016664</p>
        <p>globalsocietysports@gmail.com</p>
        <a href="/contact">Get in touch</a>
      </div>
      <div className="footer-links">
        <h4>Find us on</h4>
        <p onClick={()=>{window.open("https://www.facebook.com/GlobalSocietyin/")}}>Facebook</p>
        <a href="/contact">Email</a>
        <p onClick={()=>{window.open("https://twitter.com/arun_drus")}}>Twitter</p>
      </div>
      <div>
        <h4>Actions</h4>
          <a href="/donate">Make a donation</a>
          <a href="/home">Home</a>
          <a href="/about">About</a>
          <a href="/events">Events</a>
          <a href="/upcoming">Upcoming</a>
          {/* <a href="/login">login</a> */}
      </div>
    </footer>
    <h4 className="copyright">copyright @2020 Global Society</h4>
    </>
  );
}
 
export default Footer;