import React from 'react';
import './card.css'


const ContentCard = ({img, title, para, reverse}) =>{
  let classes = "card";
  if(reverse){
    classes = "card reverse"
  }
  return <div className={classes}>
            <img className="card-img" src={img} alt="services-cards" />
            <div className="card-content">
              <h4>{title}</h4>
              <p>{para}</p>
            </div>
        </div>
}

export default ContentCard;