import {useState, useEffect, useRef} from 'react';
// eslint-disable-next-line no-unused-vars
import {DEV_BASE_URL , PROD_BASE_URL} from '../../values';


const useFetch = (url) => {
const cacheData = useRef({});
  const [data, setData] = useState(null);
  const [status, setStatus] = useState(null);
  const [loading, setLoading] = useState(true);
  useEffect(()=>{
    const getData = async () => {
      try{
        if(cacheData.current[url]){
          // console.log("using cached");
          setData(cacheData.current[url]);  
          setStatus(200);
          setLoading(false);
        }
        else{
          const response = await fetch(PROD_BASE_URL + url);
          const data = await response.json();
          cacheData.current[url] = data;
          // console.log("fetching..");
          setData(data);
          setStatus(200);
          setLoading(false);
        }
      }
     catch(e){
       console.log(e);
       setStatus(404);
       setLoading(false);
     }
    }
    getData();
  },[url]);
  if(loading){
    return [[],404];
  }else{
    return [data,status];
  }
}
 
export default useFetch;